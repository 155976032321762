<template>
  <div class="declare">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">申报详情</span>
        <span class="banner-fMin banner-other">送政策、送服务，助推企业发展</span>
      </div>
    </div>
    <div class="declare-container width-limit" ref="declareContainer">
      <div class="d-container">
        <div v-if="sxmc" class="d-title">
          {{ sxmc }}
        </div>
        <!--<Tabs v-model="tabsValue">
          <TabPane
            v-for="item in tabs"
            :key="item.name"
            :label="item.label"
            :name="item.name"
          >
          </TabPane>
        </Tabs>-->
        <el-steps :active="stepNum" align-center>
          <el-step title="填写基本信息"></el-step>
          <el-step title="上传材料"></el-step>
          <el-step title="确认提交"></el-step>
        </el-steps>
        <div v-show="stepNum==1" class="d-base-contianer">
          <div class="b-title">申请人（法人）信息</div>
          <div v-if="tableBaseInfo.length>0">
            <div class="formItem">
            <span class="itemTitle">{{tableBaseInfo[0].title}} <span
              class="required">*</span> </span>
              <el-input class="matchParent" disabled v-model="tableBaseInfo[0].content">
              </el-input>
            </div>
            <div class="formItem">
            <span class="itemTitle">{{tableBaseInfo[1].title}} <span
              class="required">*</span> </span>
              <div class="matchParent flex-row-between">
                <el-select value="1" disabled>
                  <el-option label="统一社会信用代码" value="1"></el-option>
                </el-select>
                <el-input class="matchParent" style="margin-left: 10px" disabled
                          v-model="tableBaseInfo[1].content">
                </el-input>
              </div>
            </div>
            <div class="formItem">
            <span class="itemTitle">{{tableBaseInfo[2].title}} <span
              style="color: red">*</span> </span>
              <el-input class="matchParent" v-model="tableBaseInfo[2].content">
              </el-input>
            </div>
            <div class="formItem">
            <span class="itemTitle">{{tableBaseInfo[3].title}} <span
              style="color: red">*</span> </span>
              <div class="matchParent flex-row-between">
                <el-select value="1">
                  <el-option label="身份证" value="1"></el-option>
                </el-select>
                <el-input class="matchParent" style="margin-left: 10px"
                          v-model="tableBaseInfo[3].content">
                </el-input>
              </div>
            </div>
            <div class="formItem">
            <span class="itemTitle">{{tableBaseInfo[4].title}} <span
              style="color: red">*</span> </span>
              <el-input class="matchParent"
                        v-model="tableBaseInfo[4].content">
              </el-input>
            </div>
          </div>


          <div class="b-title">经办人基本信息</div>
          <!-- <BaseInfo :childItem="childItem"></BaseInfo> -->
          <div v-if="tableAgentInfo.length>0">
            <div class="formItem">
            <span class="itemTitle">{{tableAgentInfo[0].title}} <span
              class="required">*</span> </span>
              <el-input class="matchParent" disabled v-model="tableAgentInfo[0].content">
              </el-input>
            </div>
            <div class="formItem">
            <span class="itemTitle">{{tableAgentInfo[1].title}} <span
              style="color: red">*</span> </span>
              <div class="matchParent flex-row-between">
                <el-select value="1" disabled>
                  <el-option label="身份证" value="1"></el-option>
                </el-select>
                <el-input class="matchParent" style="margin-left: 10px" disabled
                          v-model="tableAgentInfo[1].content">
                </el-input>
              </div>
            </div>
            <div class="formItem">
            <span class="itemTitle">{{tableAgentInfo[2].title}} <span
              class="required">*</span> </span>
              <el-input class="matchParent" v-model="tableAgentInfo[2].content">
              </el-input>
            </div>
            <div class="formItem">
              <span class="itemTitle">{{tableAgentInfo[3].title}} </span>
              <el-input class="matchParent" v-model="tableAgentInfo[3].content">
              </el-input>
            </div>
            <div class="formItem">
              <span class="itemTitle">{{tableAgentInfo[4].title}} </span>
              <el-input class="matchParent" v-model="tableAgentInfo[4].content">
              </el-input>
            </div>
          </div>
          <!-- <BaseInfo :childItem="childItem"></BaseInfo> -->
          <div v-if="formContent">
            <div class="b-title flex-row-between">
              <span @click="fillData">项目信息</span>
              <el-button type="primary" @click="showFormDataDialog">历史表单数据</el-button>
            </div>
            <ParseFormViewDiv
              ref="parseFormViewDiv"
              :formData="formContent"
              size="100%"
            ></ParseFormViewDiv>
          </div>
        </div>
        <div v-show="stepNum==2">
          <TableRanking
            :itemList="materialList"
            :itemWorkList="workUpList"
            @uploadFileSuccess="uploadFileSuccess"
          ></TableRanking>
        </div>
        <div v-show="stepNum==3" style="padding: 50px" class="flex-row flex-center is-justify-center">
          <h1>{{tip}}</h1>
        </div>
        <div v-show="stepNum!=3" class="d-btns flex-row flex-center is-justify-center" id="btns">
          <el-button type="warning" :disabled="stepNum==1" @click="stepNum--">上一步</el-button>
          <el-button type="info" plain @click="submitAction(true)">暂存</el-button>
          <el-button type="primary" @click="nextStep">下一步</el-button>
          <!--<div class="d-btns" id="btns">
            <BottomBtns
              @submit="submitAction"
              @tempSave="tempSave"
              @checkInput="checkInput"
            ></BottomBtns>
          </div>-->
        </div>
      </div>
      <el-dialog title="历史表单数据选择" :visible.sync="dgVisible" width="50%">

        <div class="dialog-wrap">
          <!--<p class="dialog-title">历史表单数据</p>-->
          <!--<div class="flex-row flex-start" style="margin: 10px 0">
            <el-input
              style="width: 90%; margin-right: 10px"
              v-model="keyWord"
              placeholder="请输入附件关键字"
            ></el-input>
            <el-button @click="queryData" type="primary" plain>查询</el-button>
          </div>-->

          <div class="mt-10">
            <el-table
              :data="tableData" stripe border height="280" size="mini"
              @row-click="fillFormSubData"
              highlight-current-row>
              <el-table-column
                label="序号"
                type="index"
                width="60"
                align="center"
                style="padding: 0"
              ></el-table-column>
              <el-table-column label="事项名称" min-width="310">
                <template slot-scope="scope">
                  <span class="max-one-line">{{ scope.row.bsdata.baseinfo.sxmc }}</span>
                </template>
              </el-table-column>

              <el-table-column label="申请时间" width="210">
                <template slot-scope="scope">
                  <span class="max-one-line">{{ scope.row.createtime.split("T")[0] }}</span>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              class="mt-10"
              background
              hide-on-single-page
              :total="total"
              :page-size="pageSize"
              @current-change="changePageNum"
            ></el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  // import BaseInfo from "@/components/declareComponents/baseInfo.vue";
  // import TwoColumnTable from "@/components/twoColumnTable.vue";
  import TableRanking from "@/components/declareComponents/tableRanking.vue";
  // import BottomBtns from "@/components/declareComponents/bottomBtns.vue";
  import ParseFormViewDiv from "@/components/declareComponents/formMaking/ParseFormViewDiv";
  import {Loading} from "element-ui";
  import {mapGetters} from "vuex";

  export default {
    components: {
      ParseFormViewDiv,
      TableRanking,
    },
    computed: {
      ...mapGetters({
        loginUserInfo: "getUserInfo",
        idCardTypes: "getIdCardTypes",
        projectItem: "getCurrentProjectItem", // 从我的项目进入时有值
        childItem: "getCurrentDeclareItem",
        gsBaseInfo: "getAccountBaseInfo",
      }),
    },
    data() {
      return {
        stepNum: 1,
        tip: "",
        sxmc: "",
        showFloatingBar: false,
        tabsValue: "baseInfo",
        formData: null, // formData是请求回来的原始数据
        formContent: null, // 根据formContent去渲染页面
        bstype: "1", // 申报类型 1企业2个人 根据用户信息是否有社会信用代码判断
        tabs: [
          {
            label: "基本信息",
            name: "baseInfo",
          },
          {
            label: "附件清单",
            name: "fujian",
          },
        ],
        enterpriseInfo: {
          unitname: "",
          unitcardtype: "身份证", // 机构证件类型
          unitcardcode: "", // 统一信用代码
          legalperson: "", //法人名字
          legalcardtype: "", // 法人身份证
          legalcardnum: "", //法人身份证号
          unitaddress: "",
          unittel: "",
          unitpostcode: "",
          unitemail: "",
        },
        tableBaseInfo: [], // 基本信息
        tableAgentInfo: [], // 经办人基本信息
        materialList: [], // 网络请求回来的材料列表数据
        workUpList: [], //  通过工作台传的上传
        materials: [], // 与materialList结构一样，上传后增加files字段，如果没上传过材料 提交申报时就用materialList的值
        currentBusinessInfo: null, //从我的项目进入申报页面，获取到是申报的数据

        //事项id(guideId)
        sxid: "",
        allFormDataList: [],
        tableData: [],
        total: 0,
        pageSize: 5,
        pageNum: 1,
        dgVisible: false
      };
    },
    created() {
    },
    mounted() {
      console.log("childItem:", this.childItem);
      console.log("projectItem:", this.projectItem);
      console.log("loginUserInfo:", this.loginUserInfo);
      console.log("gsBaseInfo", this.gsBaseInfo);

      this.sxid = this.childItem.id
      //加载历史表单数据
      this.getHistoryFormDataList()
      // 增加滚动监听， 显示申报按钮
      window.addEventListener("scroll", this.handleScroll);
      // 获取证照类型
      if (!this.idCardTypes) {
        console.log("获取词典");
        this.$store.dispatch("getDictListByType", {
          dictType: "sys_id_card_type",
        });
      }
      if (this.loginUserInfo.shxydm) {
        // 申报类型 1企业2个人
        this.bstype = 1;
      } else {
        this.bstype = 2;
      }
      if (this.projectItem) {
        this.sxmc = this.projectItem.name || this.projectItem.sxmc;
        if (this.projectItem.status == 6) {
          //暂存件
          this.initTempSaveData();
        } else {
          //其他情况的
          // 如果从我的项目进入则返回所有数据
          this.getBusinessInfo();
        }
      } else {
        this.sxmc = this.childItem.name || this.childItem.sxmc;
        // 获取表单的数据 并渲染
        this.loadFromData();
        // 获取材料的数据
        this.loadMaterialList();
      }

      this.initBaseInfoUI();
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      //切换下一步骤
      nextStep() {
        let stepNum = this.stepNum
        if (stepNum == 1) {
          //检查基本信息输入
          let flag = this.checkBaseInfo()
          if (flag) {
            this.stepNum = 2;
          }
        }

        if (stepNum == 2) {
          //检查附件必传
          let flag = this.checkMaterial()
          if (flag) {
            this.$confirm(`确认申请事项${this.sxmc}?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.submitAction()
            }).catch(() => {

            });
          }
        }
      },
      //检查基本信息填写
      async checkBaseInfo() {
        let projectFormData = null;
        projectFormData = await this.$refs.parseFormViewDiv.getFormData();
        if (projectFormData == null) {
          this.$message({
            message: "请先填写必填信息",
            type: "error",
          });
          return false
        }

        let submitData = this.handleSubmitData(false, projectFormData);

        //数据必填验证
        let reporter = submitData.operator.reporter
        let agent = submitData.operator.agent

        if (!this.checkInputReporter(reporter) || !this.checkInputAgent(agent) && !isTempSave) {

          this.$alert('必填信息必须填写才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return false
        }
        return true
      },
      //检查材料
      async checkMaterial() {
        let projectFormData = null;
        projectFormData = await this.$refs.parseFormViewDiv.getFormData();

        let submitData = this.handleSubmitData(false, projectFormData);

        if (!this.checkFiles(submitData)) {
          this.$alert('必传材料必须上传才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return false
        }
        return true
      },

      fillData() {
        let data = {
          dwmc: "深圳太极云软技术有限公司",
          zcsj: "2020-09-01",
          txdz: "深圳新一代产业园3栋1402",
          email: "513@qq.com",
          zczj: "8000",
          sbbh: "12345678",
          nsbm: "0X78782376672",
          fddb: "徐某某",
          bgdh1: "0755-54367819",
          sj1: "13058058350",
          lxr: "陈某某",
          bgdh2: "0755-46567819",
          sj2: "13058058351",
          khyh: "78238378712887263",
          yhzh: "4762638273888712878",
          djlx: 7,
          fclx: 2,
          xmmc: "低碳节能减排",
          xmtze: 800,
          sssj: "2021-01-27T16:00:00.000Z",
          xmgm: "项目规模项目规模很大",
          xmnr: "这是项目内容项目内容",
        };
        if (this.$refs.parseFormViewDiv) {
          this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
        }
      },
      //表单数据共享对话框
      showFormDataDialog() {
        this.dgVisible = true
      },
      changePageNum(index) {
        this.pageNum = index
        this.tableData = []
        let allDataList = this.allFormDataList
        let pageSize = this.pageSize
        let pageNum = this.pageNum
        let endIndex
        if (pageNum * pageSize > allDataList.length) {
          endIndex = allDataList.length
        } else {
          endIndex = pageNum * pageSize
        }
        this.total = allDataList.length
        for (let i = (pageNum - 1) * pageSize; i < endIndex; i++) {
          this.tableData.push(allDataList[i])
        }
      },
      getHistoryFormDataList() {
        let userInfo = this.$store.getters.getUserInfo
        let param = {
          sxid: this.sxid
        }

        if (userInfo) {

          if (this.bstype == 1) {
            //企业,查询数据使用社会信用统一代码
            param.identify = userInfo.shxydm
          } else {
            //个人,查询数据使用身份证号码
          }
        }

        this.$httpApi.get("/dev-api/userBusiness/historyBizList", param).then(res => {
          let list = res.data
          for (let i = 0; i < list.length; i++) {
            let item = res.data[i]
            //将返回的字符串转为object
            let bsData = JSON.parse(res.data[i].bsdata)
            item.bsdata = bsData
            this.allFormDataList.push(item)
          }

          let allDataList = this.allFormDataList
          let pageSize = this.pageSize
          let pageNum = this.pageNum
          let endIndex
          if (pageNum * pageSize > allDataList.length) {
            endIndex = allDataList.length
          } else {
            endIndex = pageNum * pageSize
          }
          this.total = allDataList.length
          for (let i = (pageNum - 1) * pageSize; i < endIndex; i++) {
            this.tableData.push(allDataList[i])
          }

        })

      },
      fillFormSubData(itemdata) {

        this.formData = itemdata.bsdata.formInfo[0]
        // 填充表单
        setTimeout(() => {

          let data = JSON.parse(this.formData.subdata);
          console.log("填充的表单数据:", data);
          this.fillFormData(data);
          //关闭对话框
          this.dgVisible = false
        }, 500);

      },
      // 更新基本信息、经办人信息UI
      initBaseInfoUI() {
        if (this.gsBaseInfo) {
          this.enterpriseInfo = {
            unitname: this.gsBaseInfo.name || "",
            unitcardtype: "100008", // 机构证件类型  100008是工商营业执照
            unitcardcode: this.gsBaseInfo.shxydm || "", // 统一信用代码
            legalperson: this.gsBaseInfo.frdb || "", //法人名字
            legalcardtype: "100001", // 法人证件类型 100001是身份证
            legalcardnum: this.gsBaseInfo.frsfz || "****", //法人身份证
            unitaddress: this.gsBaseInfo.zcdz || "",
            unittel: this.gsBaseInfo.qycz || "",
            unitpostcode: "000000",
            unitemail: this.gsBaseInfo.qyyx || "",
          };
        }
        if (this.bstype === "1") {
          // 企业申报
          //  用于显示企业的基本信息
          this.tableBaseInfo = [
            {title: "法人名称", content: this.enterpriseInfo.unitname},
            {title: "法人证件号码", content: this.enterpriseInfo.unitcardcode},
            {title: "法人证件地址", content: this.enterpriseInfo.unitaddress},
            {title: "法定代表人姓名", content: this.enterpriseInfo.legalperson},
            {title: "法人代表人证件", content: this.enterpriseInfo.legalcardnum},
            {title: "机构电话", content: this.enterpriseInfo.unittel},
            {title: "申报对象", content: "单位"},
          ];
        } else {
          // 个人申报
          // 用于显示个人的基本信息
          this.tableBaseInfo = [
            {title: "申请人姓名", content: this.loginUserInfo.name || ""},
            {title: "申报对象", content: "个人"},
            {title: "申请人电话", content: this.loginUserInfo.phone || ""},
            {
              title: "申请人证件号码",
              content: this.loginUserInfo.idcard || "",
            },
            {title: "申请人邮箱", content: this.loginUserInfo.email || ""},
            {
              title: "申请人地址",
              content: this.loginUserInfo.address || "暂无",
            },
          ];
        }
        if (this.loginUserInfo) {

          // 登录人就是经办人 tableAgentInfo数组用于显示经办人的信息
          this.tableAgentInfo = [
            {title: "申请人姓名", content: this.loginUserInfo.name || ""},
            {
              title: "证件号码",
              content: this.loginUserInfo.idcard || "",
            },
            {title: "手机号码", content: this.loginUserInfo.phone || ""},
            {
              title: "固定电话",
              content: "",
            },
            {
              title: "证件地址",
              content: "",
            },
            // { title: "经办人邮箱", content: this.loginUserInfo.email || "" },
          ];
        }
      },
      // 从我的办件进入时 获取办件详情信息
      getBusinessInfo() {
        if (!this.projectItem) {
          return;
        }
        let loadingInstance = Loading.service({
          target: this.$refs.declareContainer,
        });
        this.$nextTick(() => {
          let formData = new FormData();
          formData.append("id", this.projectItem.id);
          this.$httpApi
            .post("/dev-api/userBusiness/getBusinessInfo", formData)
            .then((res) => {
              loadingInstance.close();
              console.log("getBusinessInfo>>>>>>>", res);
              if (res.code == 200) {
                this.initBusinessInfoData(res);
              }
            })
            .catch((err) => {
              loadingInstance.close();
            });
        });
      },
      // 获取到的办件详情进行渲染
      initBusinessInfoData(res) {
        let resData = JSON.parse(JSON.stringify(res.data.material));
        // 保存下请求的值
        this.currentBusinessInfo = res.data;

        // 事项名称
        if (res.data.masterinfo) {
          this.currentBusinessInfo.masterinfo.bsnum = res.data.masterinfoReporter.bsnum;
        }

        /* 附件清单，从我的工作台进去的 */
        let target = [];
        for (const item of resData) {
          item.dgVisible = false;
          item.files = item.masterinfoList;
          for (const i in item.files) {
            let f = item.files[i];
            f.name = f.filename || f.fileName;
          }
          target.push(item);
        }
        console.log("上传相关，从我的工作台进去的>>>", target);
        // TODO: materialList为空数据不好吧
        this.materialList = [];
        this.workUpList = target;

        this.formData = res.data.formData;
        if (this.formData) {
          // 解析表单显示UI
          this.formContent = JSON.parse(this.formData.formdata);
          // 填充表单
          setTimeout(() => {
            const data = JSON.parse(this.formData.subdata);
            console.log("填充的表单数据:", data);
            this.fillFormData(data);
          }, 500);
        }
      },
      initTempSaveData() {
        console.log("initTempSaveData:", this.projectItem);
        // 给材料列表赋值
        let materialList = this.projectItem.bsdata.materials;
        /* 附件清单，从我的工作台进去的 */
        let target = [];
        for (const item of materialList) {
          item.dgVisible = false;
          for (const i in item.files) {
            let f = item.files[i];
            f.name = f.filename || f.fileName;
          }
          target.push(item);
        }
        console.log("上传相关，从我的工作台进去的>>>", target);
        // TODO: materialList为空数据不好吧
        this.materialList = [];
        this.workUpList = target;

        // 渲染表单
        this.formData = this.projectItem.bsdata.formInfo[0];
        if (this.formData) {
          // 解析表单显示UI
          this.formContent = JSON.parse(this.formData.formdata);
          // 填充表单
          setTimeout(() => {
            const data = JSON.parse(this.formData.subdata);
            console.log("填充的表单数据:", data);
            this.fillFormData(data);
          }, 500);
        }
      },
      /* 上传的文件信息 */
      uploadFileSuccess(e) {
        console.log("upload OK: ", e);
        this.materials = e;
      },
      // 加载表单的json数据，进行渲染表单
      loadFromData() {
        if (this.childItem) {
          if (!this.childItem.formid) {
            console.log("----------------- 表单ID不能为空 -----------------");
            return;
          }
          let loadingInstance = Loading.service({
            target: this.$refs.declareContainer,
          });
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.$httpApi
              .get(`/dev-api/basicinfomanage/info/${this.childItem.formid}`, {})
              .then((res) => {
                console.log("res表单数据>>>:", res);
                loadingInstance.close();
                if (res.code == 200) {
                  this.formData = res.data;
                  if (this.formData.formContent) {
                    this.formContent = JSON.parse(this.formData.formContent);
                    // console.log("处理后的表单数据>>>" + JSON.stringify(this.formContent));
                    setTimeout(() => {
                      this.fillFormData();
                    }, 500);
                  }
                }
              })
              .catch((err) => {
                loadingInstance.close();
              });
          });
        }
      },
      //
      fillFormData(data) {
        if (data == null) {
          // 假数据 这数据应该是从后台请求到的
          data = this.gsBaseInfo;
        }
        if (this.$refs.parseFormViewDiv) {
          this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
        }
      },
      loadMaterialList() {
        if (this.childItem) {
          // http://192.9.206.22:8199/prod-api/dev-api/basicinfo/clxx/list?itemId=0425335001394fe1b6064baf1e4606f1&pageNum=1&pageSize=100
          this.$httpApi
            .getByBody("/dev-api/basicinfo/clxx/list", {
              itemId: this.childItem.ID, // 事项ID
              pageNum: "1",
              pageSize: "100",
            })
            .then((res) => {
              console.log("loadMaterialList:", res);
              if (res.code == 200) {
                let resData = res.rows;
                let target = [];
                for (const item of resData) {
                  item.dgVisible = false;
                  item.clmc = item.clname; // 材料名称
                  item.isneed = item.sfby; // 是否必须
                  // item.clbm = "202012150101"; // 材料编码
                  target.push(item);
                }
                // console.log("上传相关>>>", target);
                this.materialList = target;
              }
            })
            .catch((err) => {

            });
        }
      },
      handleSubmitData(isTempSave, projectFormData) {
        let submitData = {};
        if (this.projectItem && this.projectItem.status == 6) {
          let bsdata = this.projectItem.bsdata;
          let formInfo = [
            {
              bsnum: this.formData.bsnum || "", // 业务流水号
              id: this.formData.id || "", // 业务表单ID 新申报时为空
              formdata: JSON.stringify(this.formContent), // 这个可以不传
              subdata: projectFormData ? JSON.stringify(projectFormData) : "",
              formtype: this.formData.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
              formid: this.formData.formid,
            },
          ];
          //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
          let materials;
          if (this.materials && this.materials.length > 0) {
            materials = this.materials;
          } else {
            materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
          }
          submitData = {
            baseinfo: bsdata.baseinfo, // 基本信息
            formInfo: formInfo, // 表单信息
            materials: materials, // 材料信息
            operator: bsdata.operator,
          };
          console.log("从待办件进来后的要提交的数据:", submitData);
          return submitData;
        } else {
          let childItem;
          if (this.currentBusinessInfo && this.currentBusinessInfo.masterinfo) {
            // 从在办件进入masterinfo才有值
            childItem = this.currentBusinessInfo.masterinfo;
          } else {
            childItem = this.childItem;
          }

          let formInfo = [
            {
              bsnum: childItem.bsnum || "", // 业务流水号
              id: this.formData.id || "", // 业务表单ID 新申报时为空
              formdata: JSON.stringify(this.formContent), // 这个可以不传
              subdata: projectFormData ? JSON.stringify(projectFormData) : "",
              formtype: childItem.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
              formid: childItem.formid,
            },
          ];

          let baseInfo = {
            areaid: childItem.regionCode || "",
            areaname: childItem.regionName || "",
            sxid: childItem.id || "", // 事项ID
            bsnum: childItem.bsnum || "",
            bstype: childItem.bstype || this.bstype || "", // 业务类型1企业2个人
            cnsj: childItem.cnsj || "", // 承诺时间
            cnsjdw: childItem.cnsjdw || "", //承诺时间单位
            deptid: childItem.deptCode || childItem.deptid || "",
            deptname: childItem.deptName || childItem.deptname || "",
            sxmc: childItem.name || childItem.sxmc || "", // 事项名称
            jljb: childItem.jljb || "", // 是否即办件
            flowid: childItem.flowId || childItem.flowId || "", // 办理流程ID
            applicantid: childItem.applicantid || "", // 申请人ID
            appname: childItem.appname || "", // 申请人姓名
            appcompany: childItem.appcompany || "", // 申请人公司

            type: childItem.handerType || "1", // 业务来源 1PC 2窗口
            status: childItem.status || "", // 业务办理状态 0在办1正常办结2异常办结3补交4特别程序5预受理
            ssgzr: childItem.ssgzr || "", // 所属工作日
          };
          //todo 目前是走个人的,后续得加判断是否是企业bstype==1

          // 经办人信息
          let agentInfo = {
            agentuserid: this.loginUserInfo.id,
            agentname: this.tableAgentInfo[0].content || "",
            agentcertificatetype: "100001",
            agentcertificatename: "身份证",
            agentcertificatenumber: this.tableAgentInfo[1].content || "",
            agentmobilephone: this.tableAgentInfo[2].content || "",
            agentgender: "2", //性别
            agentbirthday: this.loginUserInfo.birthday || "",
            agentaddress: this.loginUserInfo.address || "",
            agentemail: this.loginUserInfo.email || "",
          };

          // 申报人信息
          let reporter = {
            operateid: this.loginUserInfo.id || "",
            operateaccount: this.loginUserInfo.loginId || "",
            optname: this.tableBaseInfo[0].content || "",
            optcertificatetype: "100001",
            optcertificatename: "身份证",
            optcertificatenumber: this.tableBaseInfo[3].content,
            optmobilephone: this.tableBaseInfo[2].content || "",
            optgender: "1",
            optbirthday: this.loginUserInfo.birthday || "",
            optaddress: this.tableBaseInfo[5].content || "",
            optemail: this.tableBaseInfo[4].content || "",
          };

          // 再次申报的时候 要用到currentBusinessInfo的值进行赋值
          if (this.currentBusinessInfo) {
            reporter = this.currentBusinessInfo.masterinfoReporter;
          }

          //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
          //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
          let materials;
          if (this.materials && this.materials.length > 0) {
            materials = this.materials;
          } else {
            materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
            // 循环赋值，将样表里面的参数多存一个yburl
          }
          console.log("材料", this.materialList)
          //处理相关字段,避免申报出错
          for (let i = 0; i < materials.length; i++) {
            let guid = materials[i].ROWGUID
            let isneed = materials[i].isneed
            let yburl = "";
            // if (materials[i].EXAMPLE_GUID[0].FILEPATH) {
            //   yburl = materials[i].EXAMPLE_GUID[0].FILEPATH
            // } else {
            //   yburl = ""
            // }

            materials[i].clbm = guid
            materials[i].clid = guid
            materials[i].yburl = yburl
            materials[i].isneed = isneed;

          }

          submitData = {
            baseinfo: baseInfo, // 基本信息
            formInfo: formInfo, // 表单信息
            materials: materials, // 材料信息
            operator: {
              apptype: this.bstype, // 申报类型 1企业 2个人
              enterprise: this.enterpriseInfo, // 企业信息
              agent: agentInfo, // 经办人信息
              reporter: reporter,
            },
          };
          console.log("提交的参数:", submitData);
          return submitData;
        }
      },
      async submitAction(isTempSave) {
        let projectFormData = null;
        if (isTempSave) {
          projectFormData = await this.$refs.parseFormViewDiv.getTempSaveData();
        } else {
          projectFormData = await this.$refs.parseFormViewDiv.getFormData();
        }

        if (projectFormData == null && !isTempSave) {
          // 暂存时不校验数据
          if (this.tabsValue != "baseInfo") {
            // 先切换为表单baseInfo页面，让用户知道哪些信息该填
            this.tabsValue = "baseInfo";
          }
          this.$message({
            message: "请先填写必填信息",
            type: "error",
          });
          return;
        }
        let submitData = this.handleSubmitData(isTempSave, projectFormData);

        //数据必填验证
        let reporter = submitData.operator.reporter
        let agent = submitData.operator.agent

        if (!this.checkInputReporter(reporter) || !this.checkInputAgent(agent)) {

          this.$alert('必填信息必须填写才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return
        }

        //附件材料检查
        if (!this.checkFiles(submitData)) {
          this.$alert('必填信息必须填写才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return
        }

        const loading = Loading.service({
          fullscreen: true,
        });
        let url = "/dev-api/business/handle/report";
        if (isTempSave) {
          url = "/dev-api/business/handle/zancun";
        }
        this.$httpApi
          .post(url, submitData)
          .then((res) => {
            loading.close();
            console.log("提交表单结果:", res);
            if (res.code == 200) {
              this.$message({
                message: isTempSave ? "暂存成功" : "提交成功",
                type: "success",
              });
              this.stepNum = 3;
              if (!isTempSave) {
                //会显示提示成功的页面
                this.tip = "业务申报成功,即将进入网上预审阶段, 请留意短信提醒。即将跳转到工作台页面..."
              } else {
                this.tip = "业务暂存成功,即将跳转到工作台页面..."
              }
              setTimeout(() => {
                this.$router.replace({path: "/workplace/index"});
              }, 3000);
            } else {
              this.$message({
                message: "提交失败",
                type: "error",
              });
            }
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "网络异常，请稍后重试",
              type: "error",
            });
          });
      },
      tempSave() {
        // console.log("暂存");
        this.submitAction(true);
      },
      //检查必须的文件列表
      checkFiles(submitData) {
        // 必传材料验证
        if (submitData.materials && submitData.materials.length > 0) {
          let materialList = submitData.materials;

          for (let i = 0; i < materialList.length; i++) {
            let item = materialList[i]
            if (item.IS_NEED == "1" || item.isneed == "1") {
              if (!item.files || item.files.length <= 0) {
                return false
              }
            }
          }
        }
        return true;
      },
      //申请人(法人)输入验证
      checkInputReporter(item) {
        if (item.optname == "") {
          return false
        }
        if (item.optmobilephone == "") {
          return false
        }
        if (item.optcertificatenumber == "") {
          return false
        }
        if (item.optemail == "") {
          return false
        }
        return true;
      },
      //经纪人表单输入验证
      checkInputAgent(item) {
        if (item.agentname == "") {
          return false
        }
        if (item.agentcertificatenumber == "") {
          return false
        }
        if (item.agentmobilephone == "") {
          return false
        }
        return true
      },
      handleScroll() {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let btnsoffsetTop = document.querySelector("#btns").offsetTop;
        // console.log("btn:", scrollTop, btnsoffsetTop, window);
        if (scrollTop > btnsoffsetTop - window.innerHeight) {
          this.showFloatingBar = false;
        } else {
          this.showFloatingBar = true;
        }
      },

      savepdf() {
        console.log("start loading。。。");
        let vm = this;
        const A4_WIDTH = 592.28;
        const A4_HEIGHT = 841.89;
        vm.$nextTick(async () => {
          let target = vm.$refs.pdf;
          vm.getPdf(target, "文件名");
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "@/styles/index.scss";

  .formItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;

    .itemTitle {
      width: 120px;
      font-weight: 600;
    }

    .required {
      color: red;
    }
  }

  .matchParent {
    flex: 1 1 0%;
  }

  .declare {
    // background: #fff;
    .banner-size {
      background: url("../../assets/images/icon_banner_declare.png");
    }

    .banner-info {
      height: 100%;
      display: flex;
      align-items: center;

      .banner-other {
        margin-left: 15px;
      }
    }
  }

  .declare-container {
    background-color: #ffffff;

    .d-container {
      padding: 30px 24px;

      .d-title {
        font-size: 24px;
        color: #000;
        font-weight: 600;
        padding-bottom: 30px;
        margin-right: 30px;
      }
    }

    .d-btns {
      padding: 40px 0;
    }
  }

  .d-base-contianer {
    // margin: 0 200px;
    min-height: 400px;

    .b-title {
      padding: 12px 0;
      margin-top: 40px;
      margin-bottom: 20px;
      border-bottom: 4px solid #000;
      color: #000;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .floatingBar {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: all 0.2s ease;
  }
</style>
